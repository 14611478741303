export const projectCategories = [
  {
    title: "Applications",
    key: "apps",
  },
  {
    title: "AI Chat / LLMs",
    key: "llmIntegration",
  },
  {
    title: "SDK & Libraries",
    key: "sdkLibraries",
  },
  {
    title: "Social Bots",
    key: "socialBots",
  },
  {
    title: "Tutorials",
    key: "tutorials",
  },
]

export const projects = {
  apps: [
    {
      name: "Image Gen - Uncensored Edition ",
      url: "https://huggingface.co/chat/assistant/66fccce0c0fafc94ab557ef2",
      description: "A powerful image generation assistant on HuggingChat using Qwen 2.5 (72B) with intelligent model selection and uncensored capabilities.",
      author: "@DeFactOfficial",
    },
    {
      name: "UR Imagine & Chat AI ",
      url: "https://perchance.org/ur-imagine-ai",
      description: "A free and limitless image generator with companion AI chat/roleplay system. Features enhanced prompting and privacy options.",
      author: "withthatway",
    },
    {
      name: "Character RP (Roblox) ",
      url: "https://www.roblox.com/games/108463136689847",
      description: "A Roblox game where players can create and roleplay with AI characters. Created by @user113.",
    },
    {
      name: "Free AI Chatbot & Image Generator",
      url: "https://freeaichat.app",
      description: "A mobile app for unlimited AI chat and image generation powered by state-of-the-art AI, featuring GPT-4 and Flux.",
      author: "@andreas_11",
    },
    {
      name: "Pollinator Android App",
      url: "https://github.com/g-aggarwal/Pollinator",
      description: "An open-source **Android app** for text-to-image generation using Pollinations.ai's endpoint.",
      author: "@gaurav_87680",
    },
    {
      name: "MIDIjourney",
      url: "https://github.com/korus-labs/MIDIjourney",
      description: "An AI-powered plugin for Ableton Live that turns text descriptions into music.",
      author: "KORUS Labs",
      repo: "https://github.com/korus-labs/MIDIjourney",
    },
    {
      name: "Pollinations Gallery ",
      url: "https://deng-xian-sheng.github.io/pollinations-img-page/",
      description: "A clean and simple gallery showcasing the community's AI-generated images using Pollinations' image feed interface.",
      author: "@deng-xian-sheng",
    },
    {
      name: "TurboReel",
      url: "https://turboreelgpt.tech/",
      description: "An open-source video generation system using AI to create engaging content from scripts.",
      author: "@pedroriosa",
      repo: "https://github.com/TurboReel/TurboReel_studio",
    },
    {
      name: "Rangrez AI",
      url: "https://rangrezai.com",
      description: "A web platform focused on inspiring, creating, and customizing designs with AI-powered tools, powered by Pollinations and GroqCloud.",
      author: "@saadaryf",
    },
    {
      name: "JustBuildThings",
      url: "https://justbuildthings.com",
      description: "A collection of AI tools for image generation, character chat, and writing powered by Pollinations.",
      author: "rasit",
    },
    {
      name: "Elixpo",
      url: "https://circuit-overtime.github.io/Elixpo_ai_pollinations",
      description: "A web interface for easy image generation with theme selection, aspect ratio options, and an internal server gallery. Powered by Pollinations.ai.",
      author: "Ayushman Bhattacharya",
      repo: "https://github.com/Circuit-Overtime/elixpo_ai_chapter",
    },
    {
      name: "Own-AI",
      url: "https://own-ai.pages.dev/",
      description: "An AI text-to-image generator powered by Pollinations.ai. Users can describe the images they want, generate them, and share them.",
      author: "Sujal Goswami",
      repo: "https://github.com/sujal-goswami/Own-AI",
    },
    {
      name: "Infinite Tales",
      url: "https://infinite-tales-rpg.vercel.app/",
      description: "A Choose Your Own Adventure RPG, dynamically narrated by AI. Customize your adventure, build your hero, and explore vast lands. Each journey is uniquely generated by AI.",
      author: "JayJayBinks",
      repo: "https://github.com/JayJayBinks/infinite-tales-rpg",
    },
    {
      name: "POLLIPAPER",
      url: "https://github.com/Tolerable/POLLIPAPER",
      description: "A dynamic wallpaper app that uses Pollinations AI to create unique desktop backgrounds. It offers weather-based prompts and customizable settings.",
      author: "@intolerant0ne",
      repo: "https://github.com/Tolerable/",
    },
    {
      name: "StorySight",
      url: "https://github.com/abiral-manandhar/storySight",
      description: "An app to help children with learning disabilities by visualizing abstract concepts. Made using **Django** and **Pollinations.ai**. Submitted to: [Devpost](https://devpost.com/software/storysight)",
    },
    {
      name: "StoryWeaver",
      url: "https://devpost.com/software/storyweaver-013xdw",
      description: "StoryWeaver crafts personalized picture books for children based on themes and prompts you choose, bringing your unique story ideas to life with AI!",
      author: "Advaith Narayanan, Omeed Sabouri, Yufan Wang",
      repo: "https://github.com/AdvaithN1/StoryWeaver",
    },
    {
      name: "Websim",
      url: "https://websim.ai/c/bXsmNE96e3op5rtUS",
      description: "A web simulation tool that integrates **Pollinations.ai** for generating AI-driven content. *Remix* the app with your own promots.",
      author: "@thomash",
    },
    {
      name: "JCode Website Builder",
      url: "https://jcode-ai-website-bulder.netlify.app/ai-website-builder/generated-projects/categories",
      description: "A website generator using Pollinations text API.",
      author: "@rtxpower",
    },
    {
      name: "AI-Bloom ",
      url: "https://ai-bloom.vercel.app/",
      description: "A minimal yet creative showcase of AI-powered visual and interactive content generation using Pollinations.ai.",
      author: "@diepdo1810",
      repoUrl: "https://github.com/diepdo1810/AI-Bloom",
    },
  ],
  llmIntegration: [
    {
      name: "SillyTavern",
      url: "https://docs.sillytavern.app/extensions/stable-diffusion/",
      description: "An **LLM frontend** for power users. Pollinations.AI permits it to generate images.",
      repo: "https://github.com/SillyTavern/SillyTavern",
    },
    {
      name: "Qwen-Agent",
      url: "https://github.com/QwenLM/Qwen-Agent",
      description: "A framework for developing agentic LLM applications.",
    },
    {
      name: "Sirius Cybernetics Elevator Challenge",
      url: "https://sirius-cybernetics.pollinations.ai/",
      description: "A Hitchhiker's Guide to the Galaxy themed LLM-based elevator game.",
      author: "@thomash_pollinations",
      repo: "https://github.com/voodoohop/sirius-cybernetics-elevator-challenge",
    },
    {
      name: "LobeChat",
      url: "https://lobehub.com/plugins/pollinations-drawing",
      description:
        "An open-source, modern-design ChatGPT/LLMs UI/Framework. Supports speech-synthesis, multi-modal, and extensible (function call) plugin system.",
      repo: "https://github.com/lobehub/lobe-chat",
    },
    {
      name: "DynaSpark AI",
      url: "https://dynaspark.onrender.com",
      description:
        "An versatile AI assistant with advanced image and text generation capabilities, integrating Pollinations.ai for image generation.",
      author: "Th3-C0der",
      repo: "https://github.com/Th3-C0der",
    },
    {
      name: "FlowGPT",
      url: "https://flowgpt.com/p/instant-image-generation-with-chatgpt-and-pollinationsai",
      description: "Generate images on-demand with **ChatGPT**!",
    },
    {
      name: "Unity AI Lab",
      url: "https://blog.unityailab.com/unity.html",
      description:
        "A specialized uncensored LLM model built on Mistral Large, focused on unrestricted conversations.",
    },
    {
      name: "gpt4free",
      url: "https://github.com/xtekky/gpt4free",
      description: "The official gpt4free repository | various collection of powerful language models.",
      author: "@xtekky"
    }
  ],
  sdkLibraries: [
    {
      name: "@pollinations/react ",
      url: "https://www.npmjs.com/package/@pollinations/react",
      description:
        "React hooks for easy integration of Pollinations.AI' image and text generation. Features usePollinationsImage, usePollinationsText, and usePollinationsChat hooks.",
      author: "@pollinations",
      repoUrl: "https://react-hooks.pollinations.ai/",
    },
    {
      name: "pypollinations ",
      url: "https://pypi.org/project/pypollinations/",
      description:
        "A comprehensive Python wrapper for accessing Pollinations.AI API endpoints. Features async support, image/text generation, and model management.",
      author: "@KTS-o7",
      repoUrl: "https://pypi.org/project/pypollinations/",
    },
    {
      name: "Pollinations.AI AI Python SDK ",
      url: "https://github.com/pollinations-ai/pollinations.ai",
      description:
        "Official Python SDK for working with Pollinations.AI generative models. Supports both image and text generation with conversation context.",
      author: "@pollinations-ai",
      repoUrl: "https://github.com/pollinations-ai/pollinations.ai",
    },
    {
      name: "Polli API Dashboard ",
      url: "https://polli-api.vercel.app",
      description:
        "A beautiful realtime dashboard monitoring text.pollinations.ai/feed with detailed statistics and analytics.",
      author: "@sugamdeol",
      repo: "https://github.com/Sugamdeol/Polli-api",
    },
  ],
  socialBots: [
    {
      name: "Discord Bot",
      url: "https://discord.gg/D9xGg8mq3D",
      description: "A **Discord bot** that uses Pollinations.AI for generating images based on user prompts.",
      author: "@Zngzy",
      repo: "https://github.com/Zingzy/pollinations.ai-bot",
    },
    {
      name: "WhatsApp Group",
      url: "https://chat.whatsapp.com/KI37JqT5aYdL9WBYMyyjDV",
      description: "A **WhatsApp group** for that allows you to generate images using Pollinations.AI.",
      author: "@dg_karma",
    },
    {
      name: "Telegram Bot",
      url: "http://t.me/pollinationsbot",
      description: "A **Telegram bot** that uses Pollinations.AI for generating images based on user prompts.",
      author: "Wong Wei Hao",
    },
    {
      name: "Anyai",
      url: "https://discord.gg/anyai",
      description:
        "A **Discord bot** and community that leverages **Pollinations.AI** for generating AI-driven content.",
      author: "@meow_18838",
    },
    {
      name: "OpenHive",
      url: "https://discord.gg/Zv3SXTF5xy",
      description:
        "A **Discord server** that bridges the gap between Discord and AI. With Beebot, access dozens of ChatGPT prompts and generate images using Pollinations.AI AI!",
      author: "@creativegpt",
    },
  ],
  tutorials: [
    {
      name: "Proyecto Descartes ",
      url: "https://proyectodescartes.org/revista/Numeros/Revista_8_2024/index.html?page=102",
      description:
        "An educational initiative integrating Pollinations.AI AI into interactive STEM learning resources. Created by Juan Gmo. Rivera.",
    },
    {
      name: "Tutorial",
      url: "https://guiadehospedagem.com.br/pollinations-ai/",
      description: "An in-depth Portuguese tutorial on using Pollinations.AI AI.",
      author: "Janderson de Sales",
    },
    {
      name: "Apple Shortcuts Guide",
      url: "https://www.youtube.com/watch?v=-bS41VTzh_s",
      description:
        "A step-by-step video guide on creating AI images using Apple Shortcuts and Pollinations.AI.",
      author: "RoutineHub",
      repo: "https://routinehub.co/shortcut/19953/",
    },
    {
      name: "Artistic Styles Book ",
      url: "https://proyectodescartes.org/iCartesiLibri/materiales_didacticos/Libro_Estilos/index.html",
      description:
        "An interactive book showcasing over 90 different artistic styles generated using Pollinations.AI. Available in HTML and PDF formats.",
      repo: "https://proyectodescartes.org/iCartesiLibri/PDF/Libro_Estilos.pdf",
    },
  ],
}
