import discord from "./discord.svg"
import youtube from "./youtube.svg"
import instagram from "./instagram.svg"
import tiktok from "./tiktok.svg"
import linkedin from "./linkedin.svg"
import github from "./github.svg"
import x from "./x.svg"

export const ICONS = {
  discord,
  github,
  youtube,
  instagram,
  x,
  tiktok,
  linkedin,
}
